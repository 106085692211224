.vessel-root {
    background: #000000;
    position: relative;
}

.trailer_slot {
    stroke: green;
}

.trailer_slot:hover{
    fill: antiquewhite;
    fill-opacity: 0.4;
    cursor: pointer;
}

.dashed_border {
    stroke-dasharray: 3;
}